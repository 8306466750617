<template>
    <div class="StoreMarket">
        <div class="StoreMarketHeader">
            <div>
                <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item><span style="font-weight: 600; color: #303133 !important;">运营推广管理</span></el-breadcrumb-item>
                <el-breadcrumb-item>店铺营销</el-breadcrumb-item>
                </el-breadcrumb>
                <div>
                    <span>行业分类：</span>
                    <el-select clearable v-model="industry_id" style="margin-top:10px" placeholder="请选择" @change="selectChange">
                    <el-option
                      v-for="item in classifyOptions"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                    </el-option>
                    </el-select>
                </div>
            </div>
           <div class="nav">
            <el-button style="margin-right:10px" class="btn-blue" @click="sendData">发送</el-button>
            <router-link :to="{name:'operateDataStoreMarketingAdd'}"><el-button class="btn-blue">创建营销数据</el-button></router-link>
           </div>
        </div>
        <div class="StoreMarketTable">
            <el-table ref="multipleTable" border :data="tableData" class="tableClass" style="height: calc(100vh - 360px);width: 100%;flex: 1;margin-top: 20px;border: 1px solid #EEEEEE;overflow-y: auto" :cell-style="{fontSize: '14px',color: '#343441'}" :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#13131B', background: '#F2F3FA'}">
                <el-table-column type="selection" label="全选" width="45"></el-table-column>
                <el-table-column label="搜索广告名称" prop="marketing_name" align="center"></el-table-column>
                <el-table-column label="备注" prop="remark" align="center"></el-table-column>
                <el-table-column label="行业分类" prop="category_name" align="center"></el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" style="color: #1122D8" @click="copy(scope.row.id)">复制</el-button>
                        <el-button type="text" style="color: #1122D8" @click="editData(scope.row.id)">编辑</el-button>
                        <el-button type="text" style="color: #EB6547" @click="deleData(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    class="pages-center"
                    :current-page="adminPages.currentPageNum"
                    :page-size="adminPages.eachPageNum"
                    layout="prev, pager, next, jumper"
                    :total="adminPages.total"
                    @current-change="adminCurrentChange"
            ></el-pagination>
        </div>
    </div>
</template>

<script>
import { storeCopy,sendMarket } from "@/utils/apis";
    export default {
        name: "StoreMarket",
        data(){
            return {
                tableData:[],
                classifyOptions:[],
                industry_id:'',
                adminPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
            }
        },
        activated() {
            this.getShowStoreMarket();
            this.getCategoryList()
        },
        // mounted() {
        //     this.getShowStoreMarket();
        //     this.getCategoryList()
        // },
        methods: {
            selectChange(){
                this.getShowStoreMarket()
            },
            getCategoryList(){
              this.$http.axiosGet(this.$api.categoryList, res=>{
                  if(res.code === 200){
                      this.classifyOptions = res.data
                  } else {
                      this.$message.warning(res.msg)
                  }
              })
            },
            async sendData(){
                let id =this.$refs.multipleTable.selection.map((item) => item.id).join('#');
                if(id && id.length > 0){
                  const result = await this.$common.sendData(sendMarket, { ids: id });
                //   result && this.getListData();
                }else{
                  this.$message.error("请勾选需要发送的数据")
                }
            },
            getShowStoreMarket(){
                let param = {
                    page: this.adminPages.currentPageNum,
                    limit: this.adminPages.eachPageNum
                };
                this.industry_id?param.category_id=this.industry_id:''
                this.$http.axiosGetBy(this.$api.showStoreMarket, param, res=>{
                    if(res.code === 200){
                        this.tableData = res.data.data;
                        this.adminPages.total = res.data.total;
                    }
                })
            },
            editData(id){
                console.log(64,id);
                this.$router.push({
                    path:'/admin/operatedata/storemarketingone',
                    query: {
                        rowId:id
                    }
                })
            },
            copy(item) {
                this.$confirm("是否复制该条数据", "提示消息", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                confirmButtonClass: "btn-blue",
                type: "warning",
            })
                .then(() => {
                    storeCopy({ id: item })
                    .then((res) => {
                      if (res.code === 200) {
                        this.$message.success("复制成功");
                        this.adminPages.currentPageNum=1
                        this.getShowStoreMarket();
                      }else{
                        this.$message.error(res.msg);
                      }
                    })
                })
                .catch(() => {
                  this.$message.info("已取消复制");
                });
            },
            deleData(id){
                this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http.axiosGetBy(this.$api.delStoreMarket, {id:id}, res=>{
                        if(res.code === 200){
                            this.$message({
                                type: 'success',
                                message: '删除成功!',
                                duration:1000,
                                onClose:()=>{
                                    this.getShowStoreMarket()
                                }
                            });

                        } else {
                            this.$message.warning(res.msg)
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                })
            },
            adminCurrentChange(val) {
                this.adminPages.currentPageNum = val;
                this.getShowStoreMarket();
            },
        }
    }
</script>

<style scoped lang="scss">
    /deep/.el-table--border::after, .el-table--group::after, .el-table::before{
        display: none;
    }
    /deep/.el-pagination{
        display: flex;
        justify-content: center;
        margin: 30px 0;
    }

    .nav{
        display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 54px;
            .el-breadcrumb {
                line-height: 40px;
            }
    }
    .tableClass{
        /deep/th{
            padding: 0!important;
            height: 45px;
            line-height: 45px;
        }
        /deep/td{
            padding: 0!important;
            height: 45px;
            line-height: 45px;
        }
    }
    .StoreMarket{
        /*overflow-x:hidden;*/
        /*overflow:scroll;*/
        height: calc(100vh - 320px);
        padding: 10px 30px 0 30px;
        .StoreMarketHeader{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .el-breadcrumb {
                line-height: 40px;
            }
        }
        .StoreMarketTable{

        }
    }
    /*.StoreMarket::-webkit-scrollbar {!*滚动条整体样式*!*/
    /*    width: 4px;     !*高宽分别对应横竖滚动条的尺寸*!*/
    /*    height: 4px;*/
    /*    scrollbar-arrow-color:red;*/
    /*    display: none;*/
    /*}*/
    /*.StoreMarket::-webkit-scrollbar-thumb {!*滚动条里面小方块*!*/
    /*    border-radius: 5px;*/
    /*    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);*/
    /*    background: rgba(0,0,0,0.2);*/
    /*    scrollbar-arrow-color:red;*/
    /*}*/
    /*.StoreMarket::-webkit-scrollbar-track {!*滚动条里面轨道*!*/
    /*    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);*/
    /*    border-radius: 0;*/
    /*    background: rgba(0,0,0,0.1);*/
    /*}*/

</style>